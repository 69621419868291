interface Props {
  role: string;
  contact: string;
  emergencyInfo: string;
  residency: string;
}

export const ProfileInformation = ({
  role,
  contact,
  emergencyInfo,
  residency,
}: Props): JSX.Element => {
  const data = [
    ["Role", role],
    ["Contact", contact],
    ["Emergency Info", emergencyInfo],
    ["Residency", residency],
  ];

  return (
    <div className='w-full'>
      <div className='max-w-xxl'>
        {data.map(([key, value]) => (
          <div
            key={key}
            className='flex text-legacyColor-neutral-1-500 border-b border-legacyColor-neutral-2-500 pb-2 mb-2'
          >
            <div className='min-w-[145px] font-bold'>{key}</div>
            <div>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
