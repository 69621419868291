import { Route, Routes } from "react-router-dom";

import { NotFound } from "@/organization_app/components/NotFound";
import { POSITIONS_PATH } from "@/organization_app/config";
import { invoicingsRoutes } from "@/organization_app/features/invoicings/routes";
import { PositionsPage } from "@/organization_app/features/positions/components/PositionsPage";

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      {invoicingsRoutes}
      <Route path={POSITIONS_PATH} element={<PositionsPage />} />
      <Route path='*' element={<NotFound />} key='not-found-routes' />
    </Routes>
  );
};
