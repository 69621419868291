import { Dispatch, SetStateAction } from "react";

import { Input } from "@/client_app/components/Form";

interface FilterByWeployeeProps {
  setSelectedPositionIds?: Dispatch<SetStateAction<string[]>>;
  communityMemberName: string;
  setCommunityMemberName: (name: string) => void;
}

function FilterByWeployee({
  setSelectedPositionIds,
  communityMemberName,
  setCommunityMemberName,
}: FilterByWeployeeProps): JSX.Element {
  const onChange = (event: InputEvent): void => {
    const target = event.target as HTMLInputElement;
    const communityMemberSearchTerm = target.value;
    const pgTrigramMinimumCharacter = 3;

    if (communityMemberSearchTerm.length >= pgTrigramMinimumCharacter) {
      setCommunityMemberName(communityMemberSearchTerm);
      if (setSelectedPositionIds) {
        setSelectedPositionIds([]);
      }
    } else {
      setCommunityMemberName("");
    }
  };

  return (
    <div className='my-6'>
      <div className='flex'>
        <div className='w-full md:w-80'>
          <Input
            defaultValue={communityMemberName}
            label={"Weployees Search"}
            name='searchByCommunityMember'
            placeholder={"Start typing Weployees name here..."}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}

export { FilterByWeployee };
