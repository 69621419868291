import clsx from "clsx";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { isFinishingSoon } from "@/client_app/features/weployees/utils/operations";
import { Checkbox } from "@/ui/Checkbox";

interface CommunityMember {
  fullName: string;
  mobileNumber?: string;
  profileImageUrl?: string;
}

interface Absences {
  thisWeek: string;
  lastWeek: string;
}

interface ActivePositionsListItemDesktopProps {
  communityMember: CommunityMember;
  finishOn: string;
  startOn: string;
  absences: Absences;
  title: string | JSX.Element;
  checked: boolean;
  newJobAcceptance: boolean;
  onChange: () => void;
  onClick: () => void;
  positionId: string;
  inAdmin?: boolean;
}

const ActivePositionsListItemDesktop = ({
  communityMember,
  finishOn,
  startOn,
  title,
  checked,
  absences,
  newJobAcceptance,
  onChange,
  onClick,
  inAdmin,
  positionId,
}: ActivePositionsListItemDesktopProps): JSX.Element => {
  const { fullName, mobileNumber, profileImageUrl } = communityMember;

  const tableCellStyleClasses =
    "border border-neutral-200 border-l-0 border-r-0 first:border-l last:border-r first:rounded-tl-xl first:rounded-bl-xl last:rounded-br-xl last:rounded-tr-xl py-4 px-5 first:pl-6 last:pr-6";

  return (
    <tr>
      <td className={clsx(tableCellStyleClasses)}>
        <div className='flex items-center gap-x-4'>
          {!inAdmin && <Checkbox checked={checked} onChange={onChange} />}
          <CommunityMemberAvatar
            profileImageUrl={profileImageUrl}
            name={fullName}
            size='SMALL'
          />
          <div className='text-left'>
            <span onClick={onClick} className='hover:underline cursor-pointer'>
              {fullName}
            </span>
            <div className='text-sm text-gray-500'>{mobileNumber}</div>
          </div>

          {newJobAcceptance && (
            <div className='py-1 px-3 rounded-full bg-primary-50'>NEW</div>
          )}
          <div
            id={`position-${positionId.substring(0, 10)}`}
            className='text-red-500 hidden pending-error'
          ></div>
        </div>
      </td>
      <td className={clsx(tableCellStyleClasses)}>{title}</td>
      <td className={clsx(tableCellStyleClasses)}>{startOn}</td>
      <td
        className={clsx(
          tableCellStyleClasses,
          isFinishingSoon(finishOn) ? "text-error-600" : ""
        )}
      >
        {finishOn}
      </td>
      <td className={clsx(tableCellStyleClasses)}>
        <div>{absences.lastWeek} last week</div>
        <div>{absences.thisWeek} this week</div>
      </td>
      <td className={clsx(tableCellStyleClasses)}></td>
    </tr>
  );
};

export { ActivePositionsListItemDesktop };
