import "@/i18n/config";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import SVG from "react-inlinesvg";
import Modal from "react-modal";

import Cross from "@assets/icons/cross.svg";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import { ProfileInformation } from "@/client_app/components/CommunityMemberDetails/ProfileInformation";
import { getCommunityMemberProfiles } from "@/client_app/components/CommunityMemberDetails/services/FetchAPIService";
import { TabSection } from "@/client_app/components/CommunityMemberDetails/TabSection";

interface Prop {
  onBack: () => void;
  isOpen: boolean;
  inAdmin?: boolean;
  positionId: string;
}

interface Profile {
  name: string;
  emergencyInfo: string;
  role: string;
  mobile: string;
  residency: string;
  profileImageUrl: string;
}
function CommunityMemberModal({
  onBack,
  isOpen,
  positionId,
  inAdmin,
}: Prop): JSX.Element {
  const [profileData, setProfileData] = useState<Profile>({
    name: "",
    emergencyInfo: "",
    role: "",
    mobile: "",
    residency: "",
    profileImageUrl: "",
  });
  useEffect(() => {
    if (positionId) {
      getCommunityMemberProfiles(positionId, inAdmin)
        .then((response) => response.json())
        .then((data) => {
          setProfileData({
            name: data.full_name,
            emergencyInfo: [
              data.emergency_contact_full_name,
              data.emergency_contact_relationship,
              data.emergency_contact_number,
            ].join(" / "),
            role: data.role,
            mobile: data.mobile_number,
            residency: data.visa_required
              ? "Visa Required"
              : "No Visa Required",
            profileImageUrl: data.profile_image_url,
          });
        });
    }
  }, [positionId]);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='Example Modal'
      shouldCloseOnOverlayClick={true}
      onRequestClose={onBack}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1020,
          top: 0,
          right: 0,
          width: "100vw",
          height: "100%",
          display: "flex",
          justifyContent: "right",
          backgroundColor: "rgba(9, 32, 36, 0.8)",
        },
        content: {
          backgroundColor: "#FFF",
          inset: 0,
          width: "85%",
          height: "100%",
          position: "relative",
          border: "1px solid #9CA1A0",
          borderRadius: "0.3rem",
          padding: 0,
        },
      }}
    >
      <section className='text-legacyColor-neutral-1-800'>
        <div className='flex items-center relative text-shade-100 border-b p-2'>
          <button onClick={onBack}>
            <SVG
              src={Cross}
              className='icon fill-current mr-4 cursor-pointer'
            />
          </button>
          <h4 className='text-shade-100 text-primary-900'>
            {profileData.name}
          </h4>
        </div>
        <div className='p-6'>
          <div className='flex'>
            <div className='w-40 mr-6 md:float-left'>
              {profileData.profileImageUrl ? (
                <CommunityMemberAvatar
                  size='EXTRA_LARGE'
                  profileImageUrl={profileData.profileImageUrl}
                  name={profileData.name}
                />
              ) : (
                <Avatar
                  className='bg-primary-50 text-primary-900 rounded-full h-40 w-40 text-6xl'
                  name={profileData.name}
                />
              )}
            </div>
            <div className='flex-1'>
              <div className='md:col-span-4 h-full'>
                <ProfileInformation
                  role={profileData.role}
                  contact={profileData.mobile}
                  emergencyInfo={profileData.emergencyInfo}
                  residency={profileData.residency}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='md:col-span-4 lg:col-span-2 py-3 md:px-3 border-t border-b md:border-0'>
              <TabSection positionId={positionId} inAdmin={inAdmin} />
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
}

export { CommunityMemberModal };
