import { tenantPath } from "helpers/tenant_path";
import { ACTIVE_POSITIONS_PATH } from "packs/client_app/config";
import { getHeaders } from "packs/client_app/config/request_headers";

export const getActivePositions = ({
  manager_id,
  community_member_name,
  limit,
  offset,
}) => {
  let query = `limit=${limit}&offset=${offset}`;
  if (manager_id) {
    query += `&manager_id=${manager_id}`;
  }

  if (community_member_name) {
    query += `&community_member_name=${community_member_name}`;
  }
  return fetch(tenantPath(`${ACTIVE_POSITIONS_PATH}?${query}`), {
    method: "GET",
    headers: getHeaders(),
  });
};
