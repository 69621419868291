export const DASHBOARD_PATH = "/weployer/dashboard";
export const ATTENDANCES_APPROVAL_PATH = "/weployer/timesheets";
export const SUBMITTED_ATTENDANCES_PATH = "/weployer/timesheets/submitted";
export const REVIEW_ATTENDANCES_PATH = "/weployer/timesheets/review";
export const APPROVAL_SUCCESS_PATH = "/weployer/timesheets/success";
export const APPROVAL_FAILURE_PATH = "/weployer/timesheets/failure";
export const SUBMITTED_ATTENDANCES_REPORT_PATH =
  "/weployer/submitted_timesheets_reports";
export const SEND_JOB_OPENING_REQUEST_HELP_PATH =
  "/weployer/job_openings/request_helps";

export const WEPLOYEES_PATH = "/weployer/weployees";
export const WEPLOYEE_ACTIVE_POSITIONS_PATH =
  "/weployer/weployees/active_positions";
export const WEPLOYEE_PAST_POSITIONS_PATH =
  "/weployer/weployees/past_positions";
export const WEPLOYEES_EXTENSION_REQUEST_SUCCESS_PATH =
  "/weployer/weployees/extension_request/success";
export const WEPLOYEES_BUYOUT_REQUEST_SUCCESS_PATH =
  "/weployer/weployees/buyout_request/success";
export const JOB_OPENING_CANDIDATE_PATH = (jobOpeningCandidateId: string) =>
  `/weployer/job_opening_candidates/${jobOpeningCandidateId}`;
export const COMMUNITY_MEMBER_PROFILES_PATH = (positionId: string) =>
  `/weployer/api/community_member_profiles/${positionId}`;
export const ACTIVE_POSITIONS_PATH = "/weployer/api/active_positions";
export const COMMENTS_PATH = "/weployer/api/comments";
export const ABSENCE_PATH = (positionId: string) =>
  `/weployer/api/position_absents/${positionId}`;
export const TAGGERS_PATH = `/weployer/api/taggers`;
export const CREATE_ABSENCE_PATH = (positionId: string) =>
  `/weployer/api/position_absents/${positionId}`;
export const WEPLOYEES_JOB_OPENING_CANDIDATE_SEND_MESSAGE_PATH = (
  jobOpeningCandidateId: string
) => `/weployer/job_opening_candidates/${jobOpeningCandidateId}/messages`;
export const WEPLOYEES_JOB_OPENING_CANDIDATE_MESSAGES_BULK_SEENS_PATH = (
  jobOpeningCandidateId: string
) =>
  `/weployer/job_opening_candidates/${jobOpeningCandidateId}/messages/bulk_seens`;
export const JOB_OPENING_DETAIL_PATH = (
  jobOpeningId: string,
  activeTab: string
) => `/weployer/job_openings/${jobOpeningId}?active_tab=${activeTab}`;
export const EDIT_COMPANY_PATH = "/weployer/company_details/edit";
export const MONASH_ATTENDANCES_APPROVAL_PATH = "/employer/timesheets";
export const MONASH_SUBMITTED_ATTENDANCES_PATH =
  "/employer/timesheets/submitted";
export const MONASH_REVIEW_ATTENDANCES_PATH = "/employer/timesheets/review";
export const MONASH_APPROVAL_SUCCESS_PATH = "/employer/timesheets/success";
export const MONASH_APPROVAL_FAILURE_PATH = "/employer/timesheets/failure";

export const STUDENTS_PATH = "/employer/students";
export const STUDENT_ACTIVE_POSITIONS_PATH =
  "/employer/students/active_positions";
export const STUDENT_PAST_POSITIONS_PATH = "/employer/students/past_positions";
export const STUDENTS_EXTENSION_REQUEST_SUCCESS_PATH =
  "/employer/students/extension_request/success";
export const STUDENTS_BUYOUT_REQUEST_SUCCESS_PATH =
  "/employer/students/buyout_request/success";
