import { tenantPath } from "helpers/tenant_path";
import i18next from "i18next";
import "@/i18n/config";
import { JOB_OPENING_DETAIL_PATH } from "packs/client_app/config";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { ActivePositionListItemMobile } from "./ActivePositionListItemMobile";
import { BuyoutRequestPreview } from "./BuyoutRequestPreview";
import { ExtensionRequestPreview } from "./ExtensionRequestPreview";
import { FilterByWeployee } from "./FilterByWeployee";
import { NoActivePositions } from "./NoActivePositions";
import { PositionTabs } from "./PositionTabs";
import { PositionType } from "./PositionType";

import { FilterByManager } from "@/client_app/components/FilterByManager";
import { Form } from "@/client_app/components/Form";
import { Pagination } from "@/client_app/components/Pagination";
import { getActivePositions } from "@/client_app/features/weployees/components/services/FetchAPIService";
import { useMediaQuery } from "@/client_app/hooks/useMediaQuery";
import { useBackendContext } from "@/client_app/stores/backend-context";
import { PositionsTable } from "@/common/components/PositionsTable";
import { useDebouncedState } from "@/hooks/useDebouncedState";
import { usePageTitle } from "@/hooks/usePageTitle";
import { PrimaryButton } from "@/ui/buttons";
import { Checkbox } from "@/ui/Checkbox";
import { flash } from "@/ui/Flash";

export type ActionsType = "EXTENSION" | "BUYOUT" | null;
const limit = 20;
export const ActivePositions = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const {
    currentClientCompanyUserId,
    terminologyEmployee,
    titlePage,
    noActivePositionImageURL,
  } = useBackendContext();
  const [selectedManager, setSelectedManager] = useState({
    id: searchParams.has("manager_id")
      ? searchParams.get("manager_id")
      : currentClientCompanyUserId,
    name: "Me",
  });
  const employees = terminologyEmployee + "s";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [selectedPositionIds, setSelectedPositionIds] = useState([]);
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [communityMemberName, setCommunityMemberName] = useDebouncedState(
    "",
    500
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<ActionsType>(null);

  const queryParams = {
    manager_id: selectedManager.id,
    community_member_name: communityMemberName,
    limit: limit,
    offset: paginationOffset,
  };

  const { data, isFetching, isSuccess, error, refetch } = useQuery(
    ["activePositions", queryParams],
    () =>
      getActivePositions(queryParams).then((response) => {
        return response.json();
      })
  );

  const activePositions = data?.activePositions || [];
  const totalCount = data?.totalCount || 0;

  const getSelecteableActivePositionIds = (action) => {
    if (!data) return [];

    return activePositions.flatMap((position) => {
      const requests =
        action == "EXTENSION"
          ? position.extensionRequests
          : position.buyoutRequests;

      const anyPendingRequests = requests.some(({ isPending }) => isPending);

      return anyPendingRequests ? [] : position.id;
    });
  };

  const handleSelectAllCheckboxChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setSelectedPositionIds(activePositions.map((position) => position.id));
      } else {
        setSelectedPositionIds([]);
      }
    },
    [selectedPositionIds, activePositions]
  );

  const handleSelectCheckboxChange = (positionId: string) => {
    const newSelectedPositionIds = new Set(selectedPositionIds);
    newSelectedPositionIds.has(positionId)
      ? newSelectedPositionIds.delete(positionId)
      : newSelectedPositionIds.add(positionId);

    setSelectedPositionIds([...newSelectedPositionIds]);
  };

  const handleRequests = useCallback(
    (requestAction: ActionsType) => () => {
      if (selectedPositionIds.length === 0) return;

      const selecteableActivePositionIds =
        getSelecteableActivePositionIds(requestAction);
      const pendingRequestPositionIds = selectedPositionIds.filter(
        (x) => !selecteableActivePositionIds.includes(x)
      );
      if (pendingRequestPositionIds.length > 0) {
        flash.alert(i18next.t("clientApp.cannotSubmitRequest"));
        pendingRequestPositionIds.forEach((positionId) => {
          const element = document.getElementById(
            `position-${positionId.substring(0, 10)}`
          );
          element?.classList.remove("hidden");
          element.innerHTML = `Pending ${requestAction.toLowerCase()} request`;
        });
      } else {
        [...document.getElementsByClassName("pending-error")].forEach(
          (element) => element.classList.add("hidden")
        );
        setIsModalOpen(true);
        setAction(requestAction);
      }
    },
    [selectedPositionIds]
  );

  const handleModalClose = () => {
    refetch();
  };

  if (error) {
    return <div>Error!</div>;
  }

  const handleBack = () => {
    setIsModalOpen(false);
    setAction(null);
  };

  const getSelectedPositions = (): PositionType[] => {
    return activePositions.filter(({ id }) => {
      return selectedPositionIds.includes(id);
    });
  };

  const jobLink = (
    jobOpeningId: string,
    jobOpeningName: string
  ): JSX.Element => {
    return (
      <a
        className='underline'
        href={tenantPath(JOB_OPENING_DETAIL_PATH(jobOpeningId, "Details"))}
      >
        {jobOpeningName}
      </a>
    );
  };

  const renderActionModal = (action) => {
    if (action == "EXTENSION") {
      return (
        <ExtensionRequestPreview
          isOpen={isModalOpen}
          onBack={handleBack}
          selectedPositions={getSelectedPositions()}
        />
      );
    } else if (action == "BUYOUT") {
      return (
        <BuyoutRequestPreview
          isOpen={isModalOpen}
          onBack={handleBack}
          selectedPositions={getSelectedPositions()}
        />
      );
    } else return;
  };

  usePageTitle(employees, titlePage);

  const renderRequestButtons = () => {
    if (isMobile) {
      return (
        <div className='space-y-4 mb-4'>
          <div className='space-y-4'>
            <PrimaryButton
              label='REQUEST EXTENSION'
              onClick={handleRequests("EXTENSION")}
              fullWidth
            />
            <PrimaryButton
              label='REQUEST BUYOUT'
              onClick={handleRequests("BUYOUT")}
              fullWidth
            />
          </div>
          <div className='flex gap-x-2 items-center'>
            <Checkbox
              checked={
                activePositions.length > 0 &&
                selectedPositionIds.length === activePositions.length
              }
              onChange={handleSelectAllCheckboxChange}
            />
            <p className='p3 font-semibold w-20'>Select all</p>
          </div>
        </div>
      );
    } else {
      return (
        <div className='md:flex gap-x-2 items-center my-4 md:mt-6 md:my-0'>
          <Checkbox
            checked={
              activePositions.length > 0 &&
              selectedPositionIds.length === activePositions.length
            }
            onChange={handleSelectAllCheckboxChange}
          />
          <p className='p3 font-semibold'>Select all</p>
          <div className='ml-4 flex space-x-4'>
            <PrimaryButton
              label='REQUEST EXTENSION'
              onClick={handleRequests("EXTENSION")}
            />
            <PrimaryButton
              label='REQUEST BUYOUT'
              onClick={handleRequests("BUYOUT")}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {renderActionModal(action)}
      <div className='pb-3 pt-6 md:pb-6'>
        <h5 className='font-bold text-shade-100 md:h4'>{employees}</h5>
      </div>

      <div className='flex justify-between border-b-2 border-neutral-200'>
        <div className='flex gap-4 lg:gap-12'>
          <PositionTabs />
        </div>
        <div>
          <Form>
            <FilterByManager
              selectedManagerId={selectedManager.id}
              setSelectedManager={setSelectedManager}
            />
          </Form>
        </div>
      </div>

      <Form>
        <div className='md:flex gap-x-6'>
          <FilterByWeployee
            setSelectedPositionIds={setSelectedPositionIds}
            communityMemberName={communityMemberName}
            setCommunityMemberName={(name: string) =>
              setCommunityMemberName(name)
            }
          />
          {renderRequestButtons()}
        </div>
      </Form>
      {isFetching && <div>Loading...</div>}
      {!isFetching && isSuccess && (
        <div>
          <>
            {activePositions.length === 0 && (
              <NoActivePositions
                noSearchResults={false}
                noActivePositionImageURL={noActivePositionImageURL}
              />
            )}
            {activePositions.length > 0 &&
              isMobile &&
              activePositions.map(
                ({
                  communityMember,
                  finishOn,
                  id,
                  startOn,
                  title,
                  newJobAcceptance,
                  jobOpeningId,
                  jobOpeningName,
                }) => (
                  <div className='mb-2 last:mb-0' key={id}>
                    <ActivePositionListItemMobile
                      communityMember={communityMember}
                      finishOn={finishOn}
                      startOn={startOn}
                      title={
                        !jobOpeningId || !jobOpeningName
                          ? title
                          : jobLink(jobOpeningId, jobOpeningName)
                      }
                      newJobAcceptance={newJobAcceptance}
                      checked={selectedPositionIds.includes(id)}
                      onChange={() => handleSelectCheckboxChange(id)}
                      positionId={id}
                    />
                  </div>
                )
              )}
            {activePositions.length > 0 && !isMobile && (
              <PositionsTable
                activePositions={activePositions}
                selectedPositionIds={selectedPositionIds}
                checkboxOnchange={(data) => handleSelectCheckboxChange(data)}
                modalOnClose={handleModalClose}
              />
            )}
          </>
        </div>
      )}
      <Pagination
        totalCount={totalCount}
        onPageChange={(data) => setPaginationOffset(data)}
        perPage={limit}
      />
    </>
  );
};
