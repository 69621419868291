import "@/i18n/config";
import { Button } from "@nextui-org/button";
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/popover";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import SVG from "react-inlinesvg";
import { Mention, MentionsInput } from "react-mentions";

import CommentIcon from "@assets/icons/comment.svg";

import { CommunityMemberAvatar } from "@/client_app/components/CommunityMemberAvatar";
import {
  getComments,
  sendComment,
} from "@/client_app/components/CommunityMemberDetails/services/FetchAPIService";
import { Tagger } from "@/client_app/components/CommunityMemberDetails/TabSection";
import { PrimaryButton } from "@/ui/buttons";
const mentionStyle = {
  backgroundColor: "#cee4e5",
  overflow: "auto",
  maxHeight: "200px",
};

interface Prop {
  recordId: string;
  recordType: string;
  absentDate: string;
  inAdmin?: boolean;
  taggers: Tagger[];
}

export function Comment({
  recordId,
  recordType,
  absentDate,
  inAdmin,
  taggers,
}: Prop): JSX.Element {
  const [comment, setComment] = useState();
  const [comments, setComments] = useState([]);
  const [mentions, setMentions] = useState([]);
  useEffect(() => {
    if (recordId) {
      getComments({ recordId, recordType, inAdmin })
        .then((response) => response.json())
        .then((data) => {
          setComments(data.comments);
        });
    }
  }, [recordId, recordType]);

  const addComment = () => {
    sendComment({
      recordId,
      recordType,
      content: comment,
      mentions: mentions,
      inAdmin,
    })
      .then((response) => response.json())
      .then((data) => {
        setComments((prev) => [
          ...prev,
          {
            ...data.comment,
          },
        ]);

        setComment(undefined);
        setMentions([]);
      });
  };

  const handleOnChange = (event, _, __, mentions) => {
    setComment(event.target.value);
    setMentions(mentions.map((mention) => mention.id));
  };

  return (
    <Popover placement='right' showArrow>
      <PopoverTrigger>
        <Button>
          <SVG
            src={CommentIcon}
            className='icon fill-current mr-4 cursor-pointer'
          />
          <span className='ml-[-12px]'>
            {comments.length > 0 && comments.length}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='bg-white border-neutral-200 border rounded-lg min-w-[400px] shadow flex flex-row justify-start'>
        <div className='px-1 py-2 w-full'>
          <div className='text-small font-bold border-neutral-200 border-b'>
            Absence: {new Date(absentDate).toLocaleDateString("en-GB")}
          </div>
          <div className='max-h-[500px] overflow-y-auto'>
            {comments.map((comment, index) => (
              <div
                className='border rounded-lg border-neutral-200 p-4 mt-4'
                key={index}
              >
                <div className='flex pb-2'>
                  <div className='self-center'>
                    {comment.profile_url ? (
                      <CommunityMemberAvatar
                        size='EXTRA_SMALL'
                        profileImageUrl={comment.profile_url}
                        name={comment.name}
                      />
                    ) : (
                      <Avatar
                        className='bg-primary-50 text-primary-900 rounded-full w-8 h-8'
                        name={comment.name}
                      />
                    )}
                  </div>
                  <div className='self-center pl-2 font-bold'>
                    {comment.name}
                  </div>
                  <div className='self-center pl-2 text-sm'>
                    {new Date(comment.time).toLocaleString("en-GB")}
                  </div>
                </div>
                <div>
                  <MentionsInput
                    className='comment-text'
                    value={comment.content}
                    disabled={true}
                  >
                    <Mention style={mentionStyle} />
                  </MentionsInput>
                </div>
              </div>
            ))}
          </div>
          <div className='divide h-[1px] bg-neutral-200 my-4'></div>
          <div className=''>
            <MentionsInput
              className='mentions border-neutral-200 border '
              placeholder='Write a comment...use @ to tag'
              value={comment}
              onChange={(event, newValue, newPlainTextValue, mentions) =>
                handleOnChange(event, newValue, newPlainTextValue, mentions)
              }
            >
              <Mention
                style={mentionStyle}
                data={taggers.map((tagger) => ({
                  ...tagger,
                  display:
                    tagger.type == "User"
                      ? tagger.full_name + " (Weploy)"
                      : tagger.full_name,
                }))}
              />
            </MentionsInput>
          </div>
          <div className='float-right'>
            <PrimaryButton label='Submit' onClick={addComment} />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
