import "@/i18n/config";
import React from "react";
import ReactPaginate from "react-paginate";

export interface Props {
  perPage: number;
  totalCount: number;
  onPageChange?: (page: number) => void;
}
export function Pagination(props: Props) {
  const { perPage, totalCount, onPageChange } = props;

  const pageCount = Math.ceil(totalCount / perPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * perPage) % totalCount;
    onPageChange(newOffset);
  };
  return (
    <div className='flex justify-center'>
      <ReactPaginate
        breakLabel='...'
        nextLabel='Next'
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        containerClassName='pagination'
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
