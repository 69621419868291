import { tenantPath } from "helpers/tenant_path";
import { getHeaders } from "packs/client_app/config/request_headers";
import { ACTIVE_POSITIONS_PATH } from "packs/organization_app/config";

export const getActivePositions = ({
  community_member_name,
  client_id_is,
  limit,
  offset,
}) => {
  let query = `limit=${limit}&offset=${offset}`;

  if (community_member_name) {
    query += `&community_member_name=${community_member_name}`;
  }
  if (client_id_is) {
    query += `&client_id_is=${client_id_is}`;
  }
  return fetch(tenantPath(`${ACTIVE_POSITIONS_PATH}?${query}`), {
    method: "GET",
    headers: getHeaders(),
  });
};
