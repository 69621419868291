import { tenantPath } from "helpers/tenant_path";
import {
  ABSENCE_PATH,
  COMMENTS_PATH,
  COMMUNITY_MEMBER_PROFILES_PATH,
  CREATE_ABSENCE_PATH,
  TAGGERS_PATH,
} from "packs/client_app/config";
import { getHeaders } from "packs/client_app/config/request_headers";

import {
  ADMIN_ABSENCE_PATH,
  ADMIN_COMMENT_PATH,
  ADMIN_COMMUNITY_MEMBER_PROFILES_PATH,
  ADMIN_TAGGERS_PATH,
} from "@/organization_app/config";

export const getCommunityMemberProfiles = (positionId: string, inAdmin?) => {
  let path = tenantPath(COMMUNITY_MEMBER_PROFILES_PATH(positionId));
  if (inAdmin) {
    path = ADMIN_COMMUNITY_MEMBER_PROFILES_PATH(positionId);
  }
  return fetch(path, {
    method: "GET",
    headers: getHeaders(),
  });
};

export const createAbsence = (
  positionId: string,
  formData: FormData,
  inAdmin?
) => {
  let path = tenantPath(CREATE_ABSENCE_PATH(positionId));
  if (inAdmin) {
    path = CREATE_ABSENCE_PATH(positionId);
  }
  return fetch(path, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content"),
    },
    body: formData,
  });
};

export const getAbsence = (positionId: string, inAdmin?) => {
  let path = tenantPath(ABSENCE_PATH(positionId));
  if (inAdmin) {
    path = ADMIN_ABSENCE_PATH(positionId);
  }
  return fetch(path, {
    method: "GET",
    headers: getHeaders(),
  });
};

export const getComments = ({ recordId, recordType, inAdmin }) => {
  const query = `record_id=${recordId}&record_type=${recordType}`;

  let path = COMMENTS_PATH;

  if (inAdmin) {
    path = ADMIN_COMMENT_PATH;
  }
  return fetch(tenantPath(`${path}?${query}`), {
    method: "GET",
    headers: getHeaders(),
  });
};

export const sendComment = ({
  recordId,
  recordType,
  content,
  mentions,
  inAdmin = false,
}) => {
  const path = inAdmin ? ADMIN_COMMENT_PATH : tenantPath(COMMENTS_PATH);
  return fetch(path, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      record_id: recordId,
      record_type: recordType,
      content: content,
      mentions: mentions,
    }),
  });
};

export const getTaggers = (positionId, inAdmin?) => {
  let path = `${tenantPath(TAGGERS_PATH)}?position_id=${positionId}`;
  if (inAdmin) {
    path = `${ADMIN_TAGGERS_PATH}?position_id=${positionId}`;
  }
  return fetch(path, {
    method: "GET",
    headers: getHeaders(),
  });
};
