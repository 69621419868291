import "@/i18n/config";
import React, { useEffect, useState } from "react";

import {
  createAbsence,
  getAbsence,
} from "@/client_app/components/CommunityMemberDetails/services/FetchAPIService";
import { Tagger } from "@/client_app/components/CommunityMemberDetails/TabSection";
import { Comment } from "@/common/components/Comment";
import { PrimaryButton, SecondaryButton } from "@/ui/buttons";

interface Props {
  positionId: string;
  inAdmin?: boolean;
  taggers?: Tagger[];
}

interface AbsenceData {
  id: string;
  date: string;
  reason: string;
  status: string | null;
  updated_at: string;
  requester: string;
  message: string;
  isEditing: boolean;
  document?: File | string | null;
  document_url: string;
}

export function Absence(props: Props) {
  const { positionId, inAdmin, taggers } = props;
  const [absenceData, setAbsenceData] = useState<AbsenceData[]>([]);
  useEffect(() => {
    if (positionId) {
      getAbsence(positionId, inAdmin)
        .then((response) => response.json())
        .then((data) => {
          setAbsenceData(data.absents);
        });
    }
  }, [positionId]);

  const [backupData, setBackupData] = useState<AbsenceData[]>([]);
  const [rowWarnings, setRowWarnings] = useState<Record<number, string>>({});

  const handleInputChange = (index: number, key: string, value: any) => {
    const updatedData = [...absenceData];
    updatedData[index] = { ...updatedData[index], [key]: value };
    setAbsenceData(updatedData);

    // Clear warnings if date changes
    if (key === "date" && rowWarnings[index]) {
      setRowWarnings((prev) => {
        const updatedWarnings = { ...prev };
        delete updatedWarnings[index];
        return updatedWarnings;
      });
    }
  };

  const toggleEditMode = (index: number, enable: boolean) => {
    const updatedData = [...absenceData];
    updatedData[index] = {
      ...updatedData[index],
      isEditing: enable,
      updated_at: new Date().toString(),
    };

    if (enable) {
      // Save a backup of the row data when entering edit mode
      setBackupData((prev) => ({
        ...prev,
        [index]: { ...absenceData[index] },
      }));
    } else {
      // Clear the backup when exiting edit mode
      setBackupData((prev) => {
        const updatedBackup = { ...prev };
        delete updatedBackup[index];
        return updatedBackup;
      });

      setRowWarnings((prev) => {
        const updatedWarnings = { ...prev };
        delete updatedWarnings[index];
        return updatedWarnings;
      });
    }

    setAbsenceData(updatedData);
  };

  const options = [
    ["submitted", "Submitted"],
    ["follow-up", "Follow-up"],
    ["done", "Done"],
  ];
  const cancelEdit = (index: number) => {
    const isNewRow = !absenceData[index]?.date && !absenceData[index]?.reason;
    if (isNewRow) {
      // Remove the new row entirely if it's canceled
      const updatedData = absenceData.filter((_, i) => i !== index);
      setAbsenceData(updatedData);
    } else {
      // Revert to the original data for existing rows
      const updatedData = [...absenceData];
      updatedData[index] = { ...backupData[index], isEditing: false }; // Restore original data and exit edit mode
      setAbsenceData(updatedData);
      setBackupData((prev) => {
        const updatedBackup = { ...prev };
        delete updatedBackup[index];
        return updatedBackup;
      });
    }
  };

  const addRow = () => {
    setAbsenceData((prev) => [
      {
        date: "",
        id: "",
        reason: "",
        status: "submitted",
        updated_at: "",
        message: "",
        document_url: "",
        requester: "",
        isEditing: true,
      },
      ...prev,
    ]);
  };

  const saveRow = (index: number) => {
    const { date } = absenceData[index];
    if (absenceData.some((item, i) => i !== index && item.date === date)) {
      setRowWarnings((prev) => ({
        ...prev,
        [index]: "The selected date already exists in the list.",
      }));
      return;
    }

    const rowData = absenceData[index];
    const formData = new FormData();

    formData.append("absence[date]", rowData.date);
    formData.append("absence[reason]", rowData.reason);
    formData.append("absence[status]", rowData.status || "");
    if (rowData.document) {
      formData.append("absence[document]", rowData.document);
    }

    createAbsence(positionId, formData, inAdmin)
      .then((response) => response.json())
      .then((data) => {
        const updatedData = [...absenceData];
        updatedData[index] = { ...updatedData[index], ...data };
        setAbsenceData(updatedData);

        setAbsenceData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = {
            ...updatedData[index],
            ...data,
            isEditing: false,
          };
          return updatedData;
        });
      });
  };
  return (
    <div>
      {!inAdmin && (
        <div className='flex justify-end'>
          <PrimaryButton label='Add New' onClick={addRow} />
        </div>
      )}
      <table
        className='w-full text-left border-separate table-auto'
        style={{ borderSpacing: "0 24px" }}
      >
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "auto" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>Reason</th>
            <th>Submitted By</th>
            <th>Status</th>
            <th>Document</th>
            <th>Updated At</th>
            <th>Comments</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {absenceData.map(
            (
              {
                date,
                id,
                reason,
                status,
                updated_at,
                requester,
                isEditing,
                message,
                document_url,
              },
              index
            ) => (
              <>
                <tr key={index}>
                  <td>
                    {isEditing ? (
                      <div>
                        <input
                          type='date'
                          value={date}
                          onChange={(e) =>
                            handleInputChange(index, "date", e.target.value)
                          }
                          className='form-control'
                        />
                      </div>
                    ) : (
                      new Date(date).toLocaleDateString("en-GB")
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <input
                        type='text'
                        value={reason}
                        onChange={(e) =>
                          handleInputChange(index, "reason", e.target.value)
                        }
                        className='form-control'
                      />
                    ) : message ? (
                      <div className='tooltip cursor-pointer'>
                        {reason || "Not found"}
                        <span className='tooltiptext'>{message}</span>
                      </div>
                    ) : (
                      reason || "Not found"
                    )}
                  </td>
                  <td>{requester}</td>
                  <td className='capitalize'>
                    {isEditing ? (
                      <select
                        defaultValue={status}
                        className='form-control'
                        onChange={(e) =>
                          handleInputChange(index, "status", e.target.value)
                        }
                      >
                        {options.map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    ) : (
                      status
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <input
                        type='file'
                        accept='.pdf,.doc,.docx,.jpg,.png'
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "document",
                            e.target.files?.[0] || null
                          )
                        }
                      />
                    ) : (
                      document_url && (
                        <a
                          href={document_url}
                          target='_blank'
                          className='underline'
                        >
                          Attached
                        </a>
                      )
                    )}
                  </td>
                  <td>
                    {updated_at && new Date(updated_at).toLocaleString("en-GB")}
                  </td>
                  <td>
                    <Comment
                      recordId={id}
                      absentDate={date}
                      recordType='AbsentRequest'
                      inAdmin={inAdmin}
                      taggers={taggers}
                    />
                  </td>
                  <td>
                    {isEditing ? (
                      <div className='flex gap-2'>
                        <PrimaryButton
                          label='Save'
                          size='SMALL'
                          onClick={() => saveRow(index)}
                        />
                        <SecondaryButton
                          label='Cancel'
                          size='SMALL'
                          onClick={() => cancelEdit(index)}
                        />
                      </div>
                    ) : (
                      <SecondaryButton
                        label='Edit'
                        size='SMALL'
                        onClick={() => toggleEditMode(index, true)}
                      />
                    )}
                  </td>
                </tr>
                {rowWarnings[index] && (
                  <p className='text-red-500 text-sm mt-1'>
                    {rowWarnings[index]}
                  </p>
                )}
              </>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
