import "@/i18n/config";
import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { getTaggers } from "@/client_app/components/CommunityMemberDetails/services/FetchAPIService";
import { Absence } from "@/client_app/components/CommunityMemberDetails/tab/Absence";
interface Props {
  positionId: string;
  inAdmin?: boolean;
}

export interface Tagger {
  id: string;
  full_name: string;
  type: string;
}
const TAB_CLASSES = "focus:outline-none cursor-pointer mr-8 text-base pb-2";
const TAB_ACTIVE_CLASSES =
  "text-legacyColor-neutral-1-900 border-primary-900 font-bold h-full border-b-2";

export function TabSection(props: Props) {
  const { positionId, inAdmin } = props;
  const [taggers, setTaggers] = useState<Tagger[]>([]);

  useEffect(() => {
    if (positionId) {
      getTaggers(positionId, inAdmin)
        .then((response) => response.json())
        .then((data) => {
          setTaggers(data.taggers);
        });
    }
  }, [positionId]);
  return (
    <Tabs className='text-primary-900'>
      <TabList className='flex'>
        <Tab className={TAB_CLASSES} selectedClassName={TAB_ACTIVE_CLASSES}>
          Absence
        </Tab>
      </TabList>
      <TabPanel key='absence'>
        <div className='mt-4'>
          <Absence
            positionId={positionId}
            inAdmin={inAdmin}
            taggers={taggers}
          />
        </div>
      </TabPanel>
    </Tabs>
  );
}
