import "@/i18n/config";
import graphqlClient from "graphql/organization_app/client";
import { useFetchClientCompaniesQuery } from "graphql/organization_app/types";
import { Form } from "packs/client_app/components/Form";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { Pagination } from "@/client_app/components/Pagination";
import { FilterByWeployee } from "@/client_app/features/weployees/components/FilterByWeployee";
import { NoActivePositions } from "@/client_app/features/weployees/components/NoActivePositions";
import { PositionsTable } from "@/common/components/PositionsTable";
import { useDebouncedState } from "@/hooks/useDebouncedState";
import { buildOptions, Select } from "@/organization_app/components/formFields";
import { getActivePositions } from "@/organization_app/features/positions/services/FetchAPIService";
import { useBackendContext } from "@/organization_app/stores/backend-context";

const limit = 20;

export function PositionsPage() {
  const [paginationOffset, setPaginationOffset] = useState(0);
  const [selectedClient, setSelectedClient] = useState("");

  const [communityMemberName, setCommunityMemberName] = useDebouncedState(
    "",
    500
  );
  const queryParams = {
    community_member_name: communityMemberName,
    limit: limit,
    offset: paginationOffset,
    client_id_is: selectedClient,
  };

  const { data, isFetching, isSuccess, error, refetch } = useQuery(
    ["activePositions", queryParams],
    () =>
      getActivePositions(queryParams).then((response) => {
        return response.json();
      })
  );

  const { data: clientCompanies } = useFetchClientCompaniesQuery(graphqlClient);

  const clientOptions = clientCompanies
    ? buildOptions(clientCompanies.clientCompanies, "id", "name")
    : [];
  const { noActivePositionImageURL } = useBackendContext();
  const onChangeClientCompany = (event: InputEvent): void => {
    const target = event.target as HTMLSelectElement;
    const clientId = target.value;
    setSelectedClient(clientId);
  };

  const handleModalClose = () => {
    refetch();
  };

  if (error) {
    return <div>Error!</div>;
  }
  const activePositions = data?.activePositions || [];
  const totalCount = data?.totalCount || 0;
  return (
    <div className='text-legacyColor-neutral-1-500'>
      <header className='px-12 py-8 bg-legacyColor-neutral-2-100'>
        <h1 className='text-headline2 mb-4'>Weployees</h1>
      </header>
      <section className='m-12'>
        <Form>
          <div className='flex'>
            <FilterByWeployee
              communityMemberName={communityMemberName}
              setCommunityMemberName={(name: string) =>
                setCommunityMemberName(name)
              }
            />
            <div className='my-6 ml-6'>
              <label className='text-gray-900'>Client Company</label>
              <div className='pt-1'>
                <Select
                  name='client_id_is'
                  id='client_id_is'
                  defaultValue={selectedClient}
                  onChange={onChangeClientCompany}
                  options={[{ value: "", label: "All" }, ...clientOptions]}
                />
              </div>
            </div>
          </div>
        </Form>
        {isFetching && <div>Loading...</div>}

        {!isFetching && isSuccess && (
          <div>
            {activePositions.length === 0 && (
              <NoActivePositions
                noSearchResults={false}
                noActivePositionImageURL={noActivePositionImageURL}
              />
            )}
            {activePositions.length > 0 && (
              <PositionsTable
                inAdmin={true}
                activePositions={activePositions}
                selectedPositionIds={[]}
                modalOnClose={handleModalClose}
              />
            )}
          </div>
        )}
        <Pagination
          totalCount={totalCount}
          onPageChange={(data) => setPaginationOffset(data)}
          perPage={limit}
        />
      </section>
    </div>
  );
}
