import clsx from "clsx";
import { tenantPath } from "helpers/tenant_path";
import "@/i18n/config";
import { JOB_OPENING_DETAIL_PATH } from "packs/client_app/config";
import { useCallback, useState } from "react";

import { CommunityMemberModal } from "@/client_app/components/CommunityMemberDetails/CommunityMemberModal";
import { ActivePositionsListItemDesktop } from "@/client_app/features/weployees/components/ActivePositionsListItemDesktop";

interface Props {
  activePositions: [];
  inAdmin?: boolean;
  selectedPositionIds: string[];
  checkboxOnchange?: (data: string) => void;
  modalOnClose?: () => void;
}
export function PositionsTable(props: Props) {
  const {
    activePositions,
    inAdmin,
    checkboxOnchange,
    selectedPositionIds,
    modalOnClose,
  } = props;

  const [positionId, setPositionId] = useState("");
  const [isCMModalOpen, setIsCMModalOpen] = useState(false);

  const tableHeaderStyleClasses =
    "text-button-md font-bold text-primary-900 uppercase";

  const openCommunityMemberModal = useCallback(
    (positionId: string) => () => {
      setPositionId(positionId);
      setIsCMModalOpen(true);
    },
    [positionId]
  );

  const jobLink = (
    jobOpeningId: string,
    jobOpeningName: string
  ): JSX.Element => {
    return (
      <a
        className='underline'
        href={tenantPath(JOB_OPENING_DETAIL_PATH(jobOpeningId, "Details"))}
      >
        {jobOpeningName}
      </a>
    );
  };

  return (
    <>
      <CommunityMemberModal
        positionId={positionId}
        isOpen={isCMModalOpen}
        inAdmin={inAdmin}
        onBack={() => {
          setIsCMModalOpen(false);
          modalOnClose?.();
        }}
      />
      <table
        className='w-full text-center border-separate table-auto'
        style={{ borderSpacing: "0 24px" }}
      >
        <thead className={clsx(tableHeaderStyleClasses)}>
          <tr>
            <th></th>
            <th>ROLE</th>
            <th>START DATE</th>
            <th>END DATE</th>
            <th>ABSENCE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {activePositions.map(
            ({
              communityMember,
              finishOn,
              id,
              startOn,
              title,
              jobOpeningId,
              jobOpeningName,
              absences,
              newJobAcceptance,
            }) => (
              <ActivePositionsListItemDesktop
                communityMember={communityMember}
                finishOn={finishOn}
                inAdmin={inAdmin}
                startOn={startOn}
                absences={absences}
                title={
                  !jobOpeningId || !jobOpeningName || inAdmin
                    ? jobOpeningName || title
                    : jobLink(jobOpeningId, jobOpeningName)
                }
                newJobAcceptance={newJobAcceptance}
                key={id}
                checked={selectedPositionIds.includes(id)}
                onChange={() => checkboxOnchange(id)}
                onClick={openCommunityMemberModal(id)}
                positionId={id}
              />
            )
          )}
        </tbody>
      </table>
    </>
  );
}
