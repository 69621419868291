import { COMMUNITY_MEMBER_PROFILES_PATH } from "@/client_app/config";

export const INVOICINGS_PATH = "/admin/invoicings";
export const POSITIONS_PATH = "/admin/weployees";
export const ACTIVE_POSITIONS_PATH = "/admin/api/active_positions";
export const ADMIN_COMMENT_PATH = "/admin/api/comments";
export const ADMIN_COMMUNITY_MEMBER_PROFILES_PATH = (positionId: string) =>
  `/admin/api/community_member_profiles/${positionId}`;
export const ADMIN_TAGGERS_PATH = `/admin/api/taggers`;
export const COMPANIES_PATH = "/admin/invoicings/client_companies";
export const ATTENDANCES_PATH =
  "/admin/invoicings/client_companies/:clientCompanyId/attendances";
export const ADMIN_ABSENCE_PATH = (positionId: string) =>
  `/admin/api/position_absents/${positionId}`;
export const ADMIN_CREATE_ABSENCE_PATH = (positionId: string) =>
  `/admin/api/position_absents/${positionId}`;
